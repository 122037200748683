import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useSeoPage } from 'hooks/useSeoPage'

import { MyDashboardsGrid } from 'features/dashboardsGrid/myDashboardsGrid'
import { PublicDashboardsGrid } from 'features/dashboardsGrid/public/publicDashboardsGrid'
import { SharedDashboardGrid } from 'features/dashboardsGrid/sharedDashboardsGrid'
import { DashboardHeader } from 'features/shareContent/dashboardHeader/dashboardHeader'

export function DashboardsPage() {
  const location = useLocation()
  const [activeTab, setActiveTab] = useState()
  const [,, tabFromUrl, ..._rest] = location.pathname.split('/')
  useSeoPage({
    title: 'Dashboards',
    description: 'Create and customise dashboards to track multiple app events. Monitor key metrics and gain insights in real-time ',
    keywords: ['dashboard', 'analytics', 'app'],
    ogTitle: 'Dashboards',
    ogDescription: 'Create and customise dashboards to track multiple app events. Monitor key metrics and gain insights in real-time ',
    // ogImage: // to do,
    // ogUrl: // to do
  })

  const tabs = [
    {
      name: 'Public',
      path: `/dashboards/public`,
      onClick: () => setActiveTab('public'),
    },
    {
      name: 'Personal',
      path: `/dashboards/personal`,
      onClick: () => setActiveTab('peronal'),
    },
    {
      name: 'Shared with you',
      path: `/dashboards/shared`,
      onClick: () => setActiveTab('shared'),
    },
  ]

  useEffect(() => {
    if (tabFromUrl) {
      setActiveTab(tabFromUrl)
    }  else {
      setActiveTab('public')
    }
  }, [tabFromUrl])

  return (
    <>
      <DashboardHeader
        tabs={tabs}
        showCreateButton={activeTab === activeTab}
      />
      {(() => {
        switch (activeTab) {
          case 'public':
            return (
              <PublicDashboardsGrid hideCreateButton={true} />
            )
          case 'personal':
            return <MyDashboardsGrid hideCreateButton={true} />
          case 'shared':
            return <SharedDashboardGrid />
          default:
            return null
        }
      })()}
    </>
  )
}
