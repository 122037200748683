import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { ButtonNew, Icon, Typography } from 'components/lib'

import Style from './SlideAddDashboard.module.css'

export const SlideAddDashboard = () => {
  const navigate = useNavigate()
  return (
    <NavLink to="/create" className={Style['slide-button']}>
      <Typography
        tag="p"
        text="There are currently no dashboards, you can create your own in the dashboard builder"
        color="gray700"
        size="s"
        align="center"
      />
      <ButtonNew
        onClick={() => navigate('/create')}
        size="large"
        startIcon={<Icon name="plus" width="16" height="16" />}
      >
        Create Dashboard
      </ButtonNew>
    </NavLink>
  )
}
