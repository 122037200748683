import { OverviewPage } from 'views/gi/ecosystem/overviewPage'
import { BasicPage } from 'views/gi/ecosystem/basicPage'
import { BreakdownPage } from 'views/gi/ecosystem/breakdownPage'
import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.giEcosystemOverview,
    view: OverviewPage,
    layout: 'redesignApp',
    title: 'Crypto Growth - Ranking',
  },
  {
    path: `/:blockchain/${paths.giEcosystem}`,
    view: BasicPage,
    layout: 'redesignApp',
  },
  {
    path: `/:blockchain/${paths.giEcosystem}/details`,
    view: BreakdownPage,
    layout: 'redesignApp',
  },
]

export default Routes
