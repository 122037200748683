import React from 'react'
import {
  Card,
  CustomAreaChart,
  Loader,
} from 'components/lib'

import { SectionTopBar } from 'features/gi/ecosystem/shared/components/sectionTopBar/sectionTopBar'
import { CoverChartSection } from '../coverChartSection/coverChartSection'
import { ValueChartLabel } from '../valueChartLabel/ValueChartLabel'
import { SectionTitle } from '../sectionTitle/sectionTitle'

export const ChartSection = ({
  loadingTimelineFlag,
  timelineData,
  singleValueData,
  title,
  dapp,
  comparison,
  round,
  formatValue,
  prefixValue
}) => {

  return (
    <Card>
      {singleValueData && (
        <ValueChartLabel
          singleValueData={singleValueData}
          comparison={comparison}
          dapp={dapp}
        />
      )}
      <SectionTopBar>
        <SectionTitle title={title} />
      </SectionTopBar>
      {loadingTimelineFlag && <div className="min-height relative"><Loader /></div>}
      {!loadingTimelineFlag &&
        timelineData?.length > 0 &&
         (
          <CustomAreaChart
            data={timelineData}
            additionalLegendData={[dapp, comparison]}
            round={round}
            formatValue={formatValue}
            prefixValue={prefixValue}
            legend={timelineData.length > 0 && 'differential' in timelineData[0] ? true : false}
          />
        )}
      {!loadingTimelineFlag && timelineData?.data?.length === 0 && <div className="min-height relative"><p className="align-xy">No data</p></div>}
      {!loadingTimelineFlag && timelineData?.message && <CoverChartSection />}
    </Card>
  )
}
