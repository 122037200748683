import React, {
  useState,
  useContext
} from 'react'

import {
  Breadcrumbs,
  Loader,
  Card,
  useMobile,
  AuthContext,
  useNavigate,
} from 'components/lib'
import { useBlockchains } from 'hooks/useBlockchains'

import { TableOfDapps } from 'features/dapps/addDapp/components/TableOfDapps'
import { AddDappTopBar } from 'features/dapps/addDapp/components/AddDappTopBar'
import { useDapps } from 'features/dapps/addDapp/hooks/useDapps'
import { NoDappContent } from 'features/dapps/addDapp/components/NoDappContent'
import { DappSettingsModal } from 'features/dapps/addDapp/components/DappSettingsModal'
import Style from './AddDapp.module.css'

export const AddDapp = () =>  {
  const breadcrumbsItems = [
    {
      key: 'add-dapp',
      url: '/create',
      name: 'Dashboard Builder',
    }
  ]
  const [previewDapp, setPreviewDapp] = useState()
  const [isDappSettingsModal, setIsDappSettingsModal] = useState<boolean>(false)
  const { dapps, isLoadingDapps } = useDapps()
  const { blockchains } = useBlockchains(`?dappanalytics=true`)
  const isMobile = useMobile()
  const authContext = useContext(AuthContext)
  const navigate = useNavigate()


  if (isDappSettingsModal && !authContext?.user) {
    navigate('/signin')
  }

  return (
    <>
      {isDappSettingsModal && authContext?.user && (
        <DappSettingsModal
          setIsDappSettingsModal={setIsDappSettingsModal}
          blockchains={blockchains}
          previewDapp={previewDapp}
        />
      )}
      <div className={Style['add-dapp']}>
        <Breadcrumbs items={breadcrumbsItems} />
        {isMobile ? (
          <>
            <AddDappTopBar
              setIsDappSettingsModal={setIsDappSettingsModal}
              setPreviewDapp={setPreviewDapp}
            />
            <div className={Style['table-mobile-container']}>
              {!isLoadingDapps && dapps && dapps?.length > 0 ? (
                <TableOfDapps
                  dapps={dapps}
                  blockchains={blockchains}
                  setPreviewDapp={setPreviewDapp}
                  setIsDappSettingsModal={setIsDappSettingsModal}
                />
              ) : null}
              {!isLoadingDapps && dapps && dapps?.length === 0 ? (
                <NoDappContent
                  setIsDappSettingsModal={setIsDappSettingsModal}
                  page="add-dapp"
                />
              ) : null}
              {isLoadingDapps && <Loader />}
            </div>
          </>
        ) : (
          <Card size="full">
            <AddDappTopBar
              setIsDappSettingsModal={setIsDappSettingsModal}
              setPreviewDapp={setPreviewDapp}
            />
            {!isLoadingDapps && dapps && dapps?.length > 0 ? (
              <TableOfDapps
                dapps={dapps}
                blockchains={blockchains}
                setPreviewDapp={setPreviewDapp}
                setIsDappSettingsModal={setIsDappSettingsModal}
              />
            ) : null}
            {!isLoadingDapps && dapps && dapps?.length === 0 ? (
              <NoDappContent
                setIsDappSettingsModal={setIsDappSettingsModal}
                page="add-dapp"
              />
            ) : null}
            {isLoadingDapps && <Loader />}
          </Card>
        )}
      </div>
    </>
  )
}