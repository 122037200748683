/***
 *
 *   TG GROWNTH INDEX TIMELINE BREAKDOWN COMPONENT
 *
 **********/

import React from 'react'

import {
  Card,
  Loader,
  CustomAreaChart,
  useMobile,
  Title,
} from 'components/lib'

import { GaugeSection} from 'features/gi/dapp/shared/components/gaugeSection/gaugeSection'
import { CoverChartSection } from 'features/gi/ecosystem/shared/components/coverChartSection/coverChartSection'
import { BreakdownContainer } from 'features/gi/ecosystem/giEcosystemBreakdownDashboard/components/breakdownContainer/breakdownContainer'
import { VerticalLine } from 'features/gi/dapp/giDappBreakdownDashboard/components/verticalLine/verticalLine'
import { TG_GROWTH_INDEX } from 'features/gi/dapp/shared/utils/constans'

export function TopSection({
  tgGrowthIndexTimeline,
  isTgGrowthIndexTimelineLoading,
  dapp,
  comparison,
}) {
  const isMobile = useMobile()
  let lastGiDataObj
  let mainMeasure

  if (tgGrowthIndexTimeline?.length > 0 && comparison && dapp) {
    const keys = Object.keys(tgGrowthIndexTimeline[0])
    if (keys.includes('differential')) {
      mainMeasure = keys.filter(item => item !== 'dimension' && item !== 'differential')[0]
  
      function extractGrowthIndexes(data) {
        const grouped = data.reduce((acc, item) => {
            if (!acc[item.differential]) {
                acc[item.differential] = []
            }
            acc[item.differential].push(item)
            return acc;
        }, {});
    
        const result = {};
        for (const [key, value] of Object.entries(grouped)) {
            const lastItem = value[value.length - 1];
            result[key] = lastItem[mainMeasure];
        }
    
        return result;
      }
  
      const gaucheData = extractGrowthIndexes(tgGrowthIndexTimeline)
  
      lastGiDataObj = {
        dApp: {
          tg_growth_index: {
            currentValue: gaucheData[dapp.name]
          }
        },
        cumulative: {
          tg_growth_index: {
            currentValue: gaucheData[comparison.name]
          }
        }
      }
    } else {
      lastGiDataObj = {
        dApp: {
          tg_growth_index: {
            currentValue: tgGrowthIndexTimeline?.[tgGrowthIndexTimeline?.length -1][TG_GROWTH_INDEX]
          }
        }
      }
    }
  } else {
    lastGiDataObj = {
      dApp: {
        tg_growth_index: {
          currentValue: tgGrowthIndexTimeline?.[tgGrowthIndexTimeline?.length -1][TG_GROWTH_INDEX]
        }
      }
    }
  }

  return (
    <Card darkMode className="mt24">
      <Title small darkMode className="mb16">TG Growth Index Timeline</Title>
      <BreakdownContainer>
        <GaugeSection
          title="dApp Growth Index"
          data={lastGiDataObj}
          loadingData={isTgGrowthIndexTimelineLoading}
          comparison={comparison}
          dapp={dapp}
        />
        {!isMobile && <VerticalLine />}
        {isTgGrowthIndexTimelineLoading && <div className="min-height relative"><Loader /></div>}
        {!isTgGrowthIndexTimelineLoading &&
        tgGrowthIndexTimeline?.length > 0 && (
          <CustomAreaChart
            darkMode
            data={tgGrowthIndexTimeline}
            round={0}
            maxValue={100}
            // metric={TG_GROWTH_INDEX}
          />
        )}
        {!isTgGrowthIndexTimelineLoading &&
        tgGrowthIndexTimeline?.data?.length === 0 && (
          <div className="min-height relative"><p className="align-xy">No data</p></div>
        )}
        {!isTgGrowthIndexTimelineLoading && tgGrowthIndexTimeline?.message && <CoverChartSection />} 
      </BreakdownContainer>
    </Card>
  )
}
