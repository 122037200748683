import React from 'react'
import {
  useSearchParams,
  useNavigate
} from 'react-router-dom'

import {
  Icon,
  IconLib,
  useMobile,
  Dropdown,
  Typography,
  Loader
} from 'components/lib'
import {
  convertFormatDate,
  formatToMoney,
  shortenAddress
} from 'utils/helpers'

import { OverflowElements } from 'features/gi/dapp/shared/components/overflowElements/OverflowElements'
import { DropdownNumbersContent } from 'features/gi/dapp/dappUsers/components/dropdownNumbersContent'
import { DropdownDateContent } from 'features/gi/dapp/dappUsers/components/dropdownDateContent'
import Style from './listOfDappUsers.module.scss'

export function ListOfDappUsers({
  users,
  verifiedAddressSegment,
  sortByParam,
  sortTypeParam,
  minDepositParam,
  maxDepositParam,
  minInteractionsParam,
  maxInteractionsParam,
  joinedStartDate,
  joinedEndDate,
  lastInteractionStartDateParam,
  lastInteractionEndDateParam,
  dappSlug,
  isLoadingData,
}) {
  const [searchParams, _setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const isMobile = useMobile()
  const openUserWidget = (userCaller) => {
    navigate(`/${dappSlug}/users/${userCaller}?${searchParams}`, true)
  }

  return (
        <div className={Style.tableContainer}>
        {!isLoadingData && users?.length > 0 ? (
          <table className={ Style.usersTable}>
            <thead>
              <tr className={Style.head}>
                <th>
                  Address
                </th>
                <th>
                  Blockchain
                </th>
                <th
                  role="button"
                  className={Style.headCell}
                >
                  <Dropdown
                    content={
                      <DropdownNumbersContent
                        column="deposits"
                        sortTypeParam={sortTypeParam}
                        sortByParam={sortByParam}
                        minName="min-deposit"
                        maxName="max-deposit"
                        minParamValue={minDepositParam}
                        maxParamValue={maxDepositParam}
                        prefix="$"
                      />
                    }
                    id="deposits"
                  >
                    <p className={Style.headCellContainer}>
                      Deposits
                      <IconLib
                        pack="own"
                        iconHeight="16"
                        iconWidth="16"
                        image="funnel"
                        color="gray600"
                        active={sortByParam === 'deposits'}
                      />
                    </p>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown
                    content={
                      <DropdownDateContent
                        column="date_joined"
                        sortTypeParam={sortTypeParam}
                        sortByParam={sortByParam}
                        minName="joined-start-date"
                        maxName="joined-end-date"
                        minParamValue={joinedStartDate}
                        maxParamValue={joinedEndDate}
                      />
                    }
                    id="date_joined"
                  >
                    <p className={Style.headCellContainer}>
                      Date Joined
                      <IconLib
                        pack="own"
                        iconHeight="16"
                        iconWidth="16"
                        image="funnel"
                        color="gray600"
                        active={sortByParam === 'date_joined'}
                      />
                    </p>
                  </Dropdown>
                </th>
                <th>
                  <Dropdown
                    content={
                      <DropdownDateContent
                        column="last_interaction_date"
                        sortTypeParam={sortTypeParam}
                        sortByParam={sortByParam}
                        minName="last-interaction-start-date"
                        maxName="last-interaction-end-date"
                        minParamValue={lastInteractionStartDateParam}
                        maxParamValue={lastInteractionEndDateParam}
                      />
                    }
                    id="last_interaction_date"
                  >
                    <p className={Style.headCellContainer}>
                      Last Interaction
                      <IconLib
                        pack="own"
                        iconHeight="16"
                        iconWidth="16"
                        image="funnel"
                        color="gray600"
                        active={sortByParam === 'last_interaction_date'}
                      />
                    </p>
                  </Dropdown>
                </th>
                <th
                  className={Style.headCell}
                >
                  <Dropdown
                    content={
                      <DropdownNumbersContent
                        column="interactions"
                        sortTypeParam={sortTypeParam}
                        sortByParam={sortByParam}
                        minName="min-interactions"
                        maxName="max-interactions"
                        minParamValue={minInteractionsParam}
                        maxParamValue={maxInteractionsParam}
                      />
                    }
                    id="interactions"
                  >
                    <p className={Style.headCellContainer}>
                      Interactions
                      <IconLib
                        pack="own"
                        iconHeight="16"
                        iconWidth="16"
                        image="funnel"
                        color="gray600"
                        active={sortByParam === 'interactions'}
                      />
                    </p>
                  </Dropdown>
                </th>
                <th>Other dApps Used</th>
              </tr>
            </thead>
            <tbody>
              {users && users.length > 0 ? users.map((user) => (
                <tr
                  className={verifiedAddressSegment === user.caller ? `${Style.row} ${Style.activeRow}` : Style.row}
                  key={user.caller}
                  onClick={() => openUserWidget(user.caller)}
                >
                  <td>
                    {user.user && <p className={Style.nameLabel}>{user.user}</p>}
                    {user.caller && Array.isArray(user.caller) ? (
                      <ul className={Style.listOfAdresses}>
                        {user.caller.slice(0, 3).map(item => {
                          return (
                            <li key={item}>
                              <span className={Style.addressText}>
                              <Icon
                                width="12"
                                height="12"
                                name="wallet"
                                color="gray800"
                              />
                                {shortenAddress(item)}
                              </span>
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      <span className={Style.addressText}>
                        <Icon
                          width="12"
                          height="12"
                          name="wallet"
                          color="gray800"
                        />
                        {isMobile ? shortenAddress(user.caller) : user.caller}
                      </span>
                    )}
                  </td>
                  <td>
                    <OverflowElements
                      data={users}
                      elements={[user.chain]}
                      type="blockchain"
                      size="m"
                      withFrame
                      id={user.name}
                    />
                  </td>
                  <td>
                    {formatToMoney(user.deposits)}
                  </td>
                  <td>
                    {convertFormatDate(user.date_joined)}
                  </td>
                  <td>
                    {convertFormatDate(user.last_interaction_date)}
                  </td>
                  <td>
                    <p className={Style.interactionBadge}>{user.interactions}</p>
                  </td>
                  <td>
                    <OverflowElements
                      data={users}
                      elements={user.other_dapps_used}
                      type="dapp"
                      size="xl"
                    />
                  </td>
                </tr>
              )) : null}
            </tbody>
          </table>
          ) : null}
          {users?.length === 0 && !isLoadingData ? (
            <Typography
              tag="p"
              text="No Data"
              size="s"
              weight="regular"
              className="min-height align-center mt48 mb24"
            />
          ) : null}
          {isLoadingData && <Loader />}
        </div>
  )
}
