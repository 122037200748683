/***
 *
 *   useFiltersParam hook
 * 
 **********/

import _React, {
  useState,
  useEffect,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  useNavigate,
  useLocation,
} from 'components/lib'
import { paths } from 'utils/paths'

import { useBasicDappsData } from 'features/gi/dapp/shared/hooks/useBasicDappsData'
import {
  verifyDateRangeParam,
  verifyBlockchainBreakdownParam
} from 'features/gi/shared/utils/helpers'

export const useVerifiedGiDappsFiltersParams = () => {
  const location = useLocation()
  const [verifiedDappSegment, setVerifiedDappSegment] = useState()
  const [verifiedCompareWithParam, setVerifiedCompareWithParam] = useState()
  const [verifiedBlockchainsParam, setVerifiedBlockchainsParam] = useState()
  const [verifiedParamsStr, setVerifiedParamsStr] = useState()
  const [searchParams, _setSearchParams] = useSearchParams()
  const [ , dappSegment ] = location.pathname.split('/')
  const verifiedDateRangeParam = verifyDateRangeParam(searchParams.get('period'))
  const compareWithParam = searchParams.get('compare with')
  const blockchainsParam = searchParams.get('blockchains')
  const verifiedBlockchainBreakdownParam = verifyBlockchainBreakdownParam(searchParams.get('blockchain breakdown'))
  const [selectedDapp, setSelectedDapp] = useState()
  const [selectedDappsToCompare, setSelectedDappsToCompare] = useState()
  const [selectedBlockchains, setSelectedBlockchains] = useState()
  const navigate = useNavigate()
  const { basicDappsData } = useBasicDappsData()

  if (!dappSegment) {
    navigate(paths.giDappOverview)
  }

  useEffect(() => {
    if (basicDappsData.length > 0) {
      if (!basicDappsData.some(obj => obj.slug?.toLowerCase() === dappSegment.toLowerCase())) {
        setVerifiedDappSegment()
        navigate(paths.notfound)
      }
      setVerifiedDappSegment(dappSegment.toLowerCase())
      const filteredSelectedDapp = basicDappsData.filter(obj => obj.slug.toLowerCase() === dappSegment.toLowerCase())[0]
      setSelectedDapp(filteredSelectedDapp)
      let finalParam = `period=${verifiedDateRangeParam.toLowerCase()}&blockchain-breakdown=${verifiedBlockchainBreakdownParam}`

      if (blockchainsParam && filteredSelectedDapp?.blockchains.length > 0) {
        const blockchainNames = blockchainsParam.split(',').map(name => name.trim().toLocaleLowerCase())
        
        const matchedBlockchains = filteredSelectedDapp.blockchains.filter(blockchain => blockchainNames.includes(blockchain.slug))
        
        if (matchedBlockchains.length > 0) {
          const matchedBlockchainsStr = matchedBlockchains.map(b => b.slug).join(',')
          setSelectedBlockchains(matchedBlockchains)
          setVerifiedBlockchainsParam(matchedBlockchainsStr)
  
          finalParam = `${finalParam}&blockchains=${matchedBlockchainsStr}`
        } else {
          setSelectedBlockchains([])
          setVerifiedBlockchainsParam(undefined)
          
        }
      } else {
        setSelectedBlockchains([])
        setVerifiedBlockchainsParam(undefined)
      }

      if (compareWithParam && !verifiedBlockchainBreakdownParam) {
        const compareWithParamArr = compareWithParam.split(',')
        let verifiedCompareWithParamArr = []
        let filteredDappsToCompareArr = []
        compareWithParamArr.forEach(param => {
          const matchingObject = basicDappsData.find(obj => obj.slug.toLowerCase() === param.toLowerCase() && obj.name !== filteredSelectedDapp.name)
          if (matchingObject) {
            verifiedCompareWithParamArr.push(param)
            filteredDappsToCompareArr.push(matchingObject)
          }
        })
        if (verifiedCompareWithParamArr.length > 0) {
          const verifiedCompareWithParamStr = verifiedCompareWithParamArr.join(',').toLowerCase()
          setVerifiedCompareWithParam(verifiedCompareWithParamStr)
          finalParam = `${finalParam}&compareWith=${verifiedCompareWithParamStr}`
          setSelectedDappsToCompare(filteredDappsToCompareArr)
        } else {
          setSelectedDappsToCompare()
          setVerifiedCompareWithParam()
        }
      } else {
        setVerifiedCompareWithParam()
        setSelectedDappsToCompare()
      }

      setVerifiedParamsStr(finalParam)
    }

  }, [
    basicDappsData,
    dappSegment,
    compareWithParam,
    verifiedDateRangeParam,
    verifiedBlockchainBreakdownParam,
    blockchainsParam
  ])

  return {
    verifiedParamsStr: verifiedParamsStr,
    verifiedDappSegment: verifiedDappSegment,
    verifiedDateRangeParam: verifiedDateRangeParam,
    verifiedCompareWithParam: verifiedCompareWithParam,
    verifiedBlockchainBreakdownParam: verifiedBlockchainBreakdownParam,
    selectedDapp: selectedDapp,
    selectedDappsToCompare: selectedDappsToCompare,
    selectedBlockchains: selectedBlockchains,
    verifiedBlockchainsParam: verifiedBlockchainsParam,
  }
}
