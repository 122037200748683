export const endpoint = {
  apiQuery: 'api/query',
  apiQueryBreakdown: 'api/query/breakdown',
  apiBlockchain: 'api/blockchain',
  apiBlockchains: 'api/blockchains',
  apiDashboardEndpoints: 'api/endpoint',
  apiFiltersLooks: 'api/filters/looks',
  apiLooks: 'api/looks',
  apiLooksSearch: 'api/looks/search',
  apiDappAIInsight: 'api/dapp/ai-insight',
  apiDappUserInsight: 'api/dapp/user-insight',
  apiDashboards: 'api/dashboards',
  apiDashboard: 'api/dashboard',
  apiDashboardFiltersSuggestions: 'api/dashboard/filters-suggestions',
  apiProject: 'api/project',
  apiProjectUser: 'api/project/owned',
  apiProjectSharedToUser: 'api/project/user-shared',
  apiActivities: 'api/activities',
  dapps: 'api/dapps',
  dappPlans: 'api/plan/dapp',
  hubspotActivity: 'api/user/hubspot/activity',
  addEmailWithAccessToProject: 'api/project/add-emails-with-access',
  addEmailWithAccessToDashboard: 'api/dashboard/add-emails-with-access',
  apiGiBlockchainsList: 'db-api/growth-index/overview',
  apiGiBasicData: 'db-api/growth-index/basic-data',
  apiGiComparison: 'db-api/growth-index/comparison',
  apiGiBasicTimelineData: 'db-api/growth-index/basic-timeline-data',
  apiGiTimelineData: 'db-api/growth-index/timeline-data',
  apiGiDappsList: 'db-api/growth-index/dapp/overview',
  apiGiDappBasicTimelineData: 'db-api/growth-index/dapp/basic-timeline-data',
  apiGiDappTimelineData: 'db-api/growth-index/dapp/timeline-data',
  apiGiDappBasicData: 'db-api/growth-index/dapp/basic-data',
  apiGiDappUserInsightTimeline: 'api/dapp/address-profile'
}

export const paths = {
  dashboard: '/dashboard',
  project: '/project',
  dashboardTokenguard: '/dashboard/tokenguard',
  giEcosystemOverview: '/crypto-growth',
  giEcosystem: 'growth',
  giDappOverview: '/dapp-ranking',
  giDapp: 'dapp-growth',
  notfound:'/notfound'
}
