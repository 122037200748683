/***
 *
 *   useDashboards hook
 *   fetch, format and return dashboards
 *
 **********/

import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { TDashboard } from 'types/dashboard/dashboard'
import { fetchDashboards } from 'utils/fetches/dashboard'

export function useDashboards(param: string) {
  const [dashboards, setDashboards] = useState<Array<TDashboard>>([])
  const [isLoadingDashboards, setIsLoadingDashboards] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingDashboards(true)
        const data = await fetchDashboards(param)
        setDashboards(data)
        setIsLoadingDashboards(false)
      } catch (err) {
        setIsLoadingDashboards(false)
        toast.error('Upss.. There was a problem to fetch dashboards')
      }
    }

    fetchData()
  }, [])

  return {
    dashboards,
    isLoadingDashboards,
    setDashboards,
    setIsLoadingDashboards
  }
}
