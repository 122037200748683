import React from 'react'

import { Typography } from 'components/lib'
import { TBlockchainOption } from 'types/blockchain'
import { TDapp } from 'types/dapp'

import { handleImageSource } from 'features/gi/dapp/shared/utils/helpers'
import Style from './ElementsTooltipInfo.module.css'

export const ElementsTooltipInfo = ({ list, type, size }) => {

  return (
    <div className={Style['info-tooltip-container']}>
      <ul className={Style['list']}>
        {list?.map((element: TBlockchainOption | TDapp, index: number) => (
          <li
            key={`${element.name}${index}`}
            className={Style['list-item']}
          >
            <img
              src={handleImageSource(element, type)}
              alt=""
              className={Style[`logo-size-${size}`]}
            />
            <Typography
              tag="p"
              size="s"
              text={element?.name}
              weight="regular"
              color="gray700"
            />
          </li>
        ))}
      </ul>
    </div>
  )
}