/***
 *
 *   GI DAPP BASIC DASHBOARD COMPONENT
 *
 **********/

import React from 'react'

import {
  Loader,
  Breadcrumbs,
  useLocation,
} from 'components/lib'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import { paths } from 'utils/paths'

import { BasicValuesSection } from 'features/gi/dapp/giDappBasicDashboard/components/basicValuesSection/basicValuesSection'
import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import { GiDappAIInsights } from 'features/gi/dapp/giDappBasicDashboard/components/giDappAIInsights/giDappAIInsights'
import { DappSankeySection } from 'features/gi/dapp/giDappBasicDashboard/components/dappSankeySection/dappSankeySection'
import { TopUsers } from 'features/gi/dapp/giDappBasicDashboard/components/topUsers/topUsers'
import { DappBasicFilters } from 'features/gi/dapp/giDappBasicDashboard/components/dappBasicFilters/DappBasicFilters'
import { DashboardsSection } from 'features/gi/shared/components/dashboardsSection/DashboardsSection'

export function GiDappBasicDashboard() {
  const {
    selectedDapp,
    verifiedDappSegment,
    verifiedParamsStr,
    verifiedBlockchainsParam,
    selectedBlockchains,
    selectedDappsToCompare,
    verifiedCompareWithParam,
    verifiedDateRangeParam,
    verifiedBlockchainBreakdownParam,
  } = useVerifiedGiDappsFiltersParams()
  const location = useLocation()
  const [ , firstSegment ] = location.pathname.split('/')
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
    {
      key: 'dapp-growth',
      url: `/${firstSegment}/${paths.giDapp}${location.search}`,
      name: selectedDapp?.name ? `${selectedDapp?.name} - Growth` : 'Growth'
    },
  ]
  useSeoPage({
    title: `${selectedDapp?.name || ' '} - Crypto`,
    description: `View ${selectedDapp?.name || ' '} crypto app detailed metrics, and analyse your user flow, read weekly insights & analyse activity`,
    keywords: `${selectedDapp?.name || ' '} app, insights, user flow, activity`,
    ogTitle: `${selectedDapp?.name || ' '} - Crypto`,
    ogDescription: `View ${selectedDapp?.name || ' '} crypto app detailed metrics, and analyse your user flow, read weekly insights & analyse activity`,
    ogImage: selectedDapp?.icon,
    // ogUrl: to set,
  })

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      {verifiedDappSegment && verifiedParamsStr ? (
        <>
          <BasicValuesSection />
          <DappBasicFilters
            selectedDapp={selectedDapp}
            selectedDappsToCompare={selectedDappsToCompare}
            verifiedDappSegment={verifiedDappSegment}
            verifiedCompareWithParam={verifiedCompareWithParam}
            verifiedDateRangeParam={verifiedDateRangeParam}
            verifiedBlockchainBreakdownParam={verifiedBlockchainBreakdownParam}
            verifiedBlockchainsParam={verifiedBlockchainsParam}
            selectedBlockchains={selectedBlockchains}
          />
          <GiDappAIInsights
            dappName={selectedDapp?.name}
            selectedBlockchains={selectedBlockchains}
            title="Last Week Insights"
          />
          {selectedBlockchains.length > 0 || selectedBlockchains.length === 0 ? (
            <>
              <DappSankeySection
                dappSlug={selectedDapp?.slug}
                blockchains={selectedBlockchains}
              />
              <TopUsers
                dappSlug={selectedDapp?.slug}
                blockchains={selectedBlockchains}
              />
            </>
          ): null}
          {selectedDapp?.dapp_analytics_dapp && (
            <DashboardsSection
              type="dapp"
              id={selectedDapp?.id}
              slug={selectedDapp?.slug}
            />
          )}
        </>
        ) : (
          <div className="min-height relative"><Loader /></div>
      )}
    </>
  )
}