/***
 *
 *   OVERVIEW
 *   Homepage
 *   List of blockchain with growth index data
 *
 **********/

import React from 'react'

import {
  Animate,
  Breadcrumbs,
  MainContentContainer,
} from 'components/lib'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import { paths } from 'utils/paths'

import { GiEcosystemOverview } from 'features/gi/ecosystem/giEcosystemOverview/giEcosystemOverview'
import ogImage from 'assets/images/og/ecosystem-ranking.png'

export function OverviewPage() {
  useSeoPage({
    title: 'Crypto Activity Ranking',
    description: 'Explore top crypto, compare their user & developer activity with detailed activity ranking for better fundamental price predictions.',
    keywords: ['crypto activity', 'developer activity', 'ranking'],
    ogTitle: 'Crypto Activity Ranking',
    ogDescription: 'Explore top crypto, compare their user & developer activity with detailed activity ranking for better fundamental price predictions.',
    ogImage: ogImage,
    // ogUrl: to do
  })
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giEcosystemOverview,
      name: 'Ecosystem Ranking',
    },
  ]

  return (
    <MainContentContainer>
      <Animate type="pop">
        <div className="mb24">
          <Breadcrumbs items={breadcrumbsItems}  />
        </div>
        <GiEcosystemOverview />
      </Animate>
    </MainContentContainer>
  )
}