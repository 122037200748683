import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  CustomSelect,
  SearchInput,
  IconLib,
  Icon,
  CustomSelectNew,
} from 'components/lib'

import { useVerifiedGiDappsFiltersParams } from 'features/gi/dapp/shared/hooks/useVerifiedGiDappsFiltersParams'
import Style from './dappUsersFilters.module.scss'

export const DappUsersFilters = ({
  pageSize,
  searchAddress,
  usedDappsOptions,
  selectedUsedDapps
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [blockchainsOptionsOfDapp, setBlockchainsOptionsOfDapp] = useState()
  const [isFilterReady, setIsFilterReady] = useState(false)
  const {
    selectedDapp,
    verifiedBlockchainsParam,
    selectedBlockchains
  } = useVerifiedGiDappsFiltersParams()

  const handleOtherDappsUsedFilter = (option) => {
    const optionsValuesStr = option.map(option => option.value).join(',')
    searchParams.delete('other-dapps-used')
    if (optionsValuesStr) {
      searchParams.append('other-dapps-used', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  const handleBlockchainFilter = (option) => {
    const optionsValuesStr = option.map(option => option.value).join(',')
    searchParams.delete('blockchains')
    if (optionsValuesStr) {
      searchParams.append('blockchains', optionsValuesStr)
    }
    setSearchParams(searchParams)
  }

  useEffect(() => {
    if ((selectedUsedDapps?.length === 0 || selectedUsedDapps?.length > 0) && usedDappsOptions?.length > 0) {
      setIsFilterReady(true)
    }

    if (selectedDapp) {
      const onlyMainnetBlockchains = selectedDapp.blockchains.filter(item => item.network === 'Mainnet')
      const modifiedBlockchains = onlyMainnetBlockchains.map((item) => {
        return (
          {
            label: item.name,
            value: item.slug,
            icon: item.logo,
            name: item.name,
            slug: item.slug
          }
        )
      })
      setBlockchainsOptionsOfDapp(modifiedBlockchains)
    }

  }, [
    JSON.stringify(selectedUsedDapps),
    JSON.stringify(usedDappsOptions),
    selectedDapp,
  ])

  // useEffect(() => {
  //   if (verifiedDappSegment) {
  //     setIsFiltersReadyToDisplay(true) 
  //   }


  // }, [
  //   verifiedDappSegment,
  //   verifiedDateRangeParam,
  //   verifiedCompareWithParam,
  //   selectedDappsToCompare,
  //   selectedDapp
  // ])

  const positionQuantityOptions = [
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    }
  ]

  const _eventsCallsOptions = {
    label: 'Events / Calls',
    value: '',
    // icon: blockchain,
  }

  const handlePositionQuantity = (option) => {
    searchParams.delete('page')
    searchParams.append('page', 1)
    searchParams.delete('page-size')
    searchParams.append('page-size', option.value)
    setSearchParams(searchParams)
  }

  const handleSearchScAddress = (e) => {
    if (e) {
      searchParams.delete('search-address')
      searchParams.delete('page')
      searchParams.append('search-address', e)
      setSearchParams(searchParams)
    } else {
      searchParams.delete('search-address')
      searchParams.delete('page')
      setSearchParams(searchParams)
    }
  }

  return (
    <section className={Style.filtersSection}>
      {isFilterReady && (
        <>
          <div className={Style.leftColumn}>
            <CustomSelect
              isSearchable={false}
              name="Events / Calls"
              disabled
              placeholderIcon={<IconLib size="16" image="circle" color="gray900" />}
              placeholder="Events / Calls"
              size="medium"
            />
            <CustomSelect
              isSearchable={false}
              isMulti
              name="Other dApps Used"
              prefix="Other dApps Used:"
              classNamePrefix="react-select-with-icon"
              defaultValue={
                selectedUsedDapps ? 
                selectedUsedDapps.map(item => ({
                  label: item.label,
                  value: item.value
                })) 
              : null}
              placeholderIcon={<Icon height="16" width="16" name="dapp" color="gray900" />}
              placeholder="Other dApps Used"
              options={usedDappsOptions}
              change={handleOtherDappsUsedFilter}
              size="medium"
            />
            {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length > 1 ? (
              <CustomSelect
                isSearchable={false}
                isMulti
                name="Blockchain"
                prefix="Blockchain:"
                classNamePrefix="react-select-multi-with-icon"
                defaultValue={
                  selectedBlockchains ? 
                  selectedBlockchains.map(item => ({
                    label: item.name,
                    value: item.slug,
                    icon: item?.icon || item?.logo
                  })) 
                  : null}
                placeholder="Blockchain"
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                size="medium"
              />
            ) :  null}
            {blockchainsOptionsOfDapp && blockchainsOptionsOfDapp.length === 1 ? (
              <CustomSelectNew
                id="blockchain"
                isSearchable
                isClearable
                prefix="Blockchain:"
                name="blockchain"
                defaultValue={{
                  value: blockchainsOptionsOfDapp[0].value,
                  label: blockchainsOptionsOfDapp[0].label,
                  icon: blockchainsOptionsOfDapp[0].icon
                }}
                options={blockchainsOptionsOfDapp}
                change={handleBlockchainFilter}
                disabled
                size="medium"
              />
            ) :  null}
            <SearchInput
              throttle={1000}
              action={(e) => handleSearchScAddress(e)}
              size="small"
              placeholder="Wallet Address"
              value={searchAddress}
              icon={<IconLib pack="own" image="magnifier" />}
            />
          </div>
          <CustomSelectNew
            isSearchable={false}
            withArrow
            prefix="Show on page:"
            defaultValue={{
              value: pageSize,
              label: pageSize.toString(),
            }}
            options={positionQuantityOptions}
            change={handlePositionQuantity}
            size="medium"
          />
        </>
      )}
    </section>
  )
}
