import React, {
  useEffect,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'

import {
  ButtonNew,
  ModalNew,
  Typography,
  Line
} from 'components/lib'
import {
  TDappToAdd,
  DappToAddSchema,
  TSingleDappDetails,
} from 'types/dapp'
import { logger } from 'utils/logger'
import { TBlockchains } from 'types/blockchain'

import { removeFileFieldsFromDappObj } from 'features/dapps/addDapp/utils/helpers'
import { GeneralSection } from 'features/dapps/addDapp/components/GeneralSection'
import { SmartContractsSection } from 'features/dapps/addDapp/components/SmartContractsSection'
import {
  fetchDapp,
  fetchGetDapp,
  fetchStartDappIndexing
} from 'features/dapps/addDapp/utils/fetches'
import Style from './DappSettingsModal.module.css'

type TDappSettingsModalProps = {
  setIsDappSettingsModal: (value: boolean) => void;
  blockchains: TBlockchains;
  previewDapp?: TSingleDappDetails;
}

export const DappSettingsModal = ({
  setIsDappSettingsModal,
  blockchains,
  previewDapp,
}: TDappSettingsModalProps) => {
  const navigate = useNavigate()
  const [previewMode, setPreviewMode] = useState<boolean>(false)
  const [dappToAdd, setDappToAdd] = useState<TDappToAdd>({
    addedBy: 'admin',
    blockchain: 'aleph-zero',
    abis: [
      {
        name: '',
        address: '',
        abi: null,
        id: '1'
      }
    ]
  })

  const startIndexingDapp = async (dappId: string) => {
    try {
      await fetchStartDappIndexing({
        id: dappId,
        fromBlock: 0
      })

    } catch (err) {
      if (axios.isAxiosError(err)) {
        toast.error(`Indexing Error: ${err?.response?.data?.message.toString()}`)
      } else {
        toast.error(err.toString())
      }
    }
  }
  
  const saveDapp = async () => {
    const dappToSend = removeFileFieldsFromDappObj(dappToAdd)
    try {
      const validatedDappToAdd = DappToAddSchema.safeParse(dappToSend)
      if (!validatedDappToAdd.success) {
        logger.error(validatedDappToAdd.error)
        throw Error('Not all required fields have been completed')
      }
      const response = await fetchDapp(dappToSend)
      const dappId = response?.output?.id
      setIsDappSettingsModal(false)
      toast.success(
        <>
          <Typography
            text="Your dApp has been added, we’re indexing it."
            tag="p"
            size="m"
            weight="regular"
            color="primary700"
          />
          <Typography
            text="This will take around 30 minutes, we will email you once it’s done"
            tag="p"
            size="m"
            weight="regular"
            color="primary700"
          />
        </>
      )

      await startIndexingDapp(dappId)

      navigate('/create')

    } catch (err) {      
      if (axios.isAxiosError(err)) {
        toast.error(err?.response?.data?.message.toString())
      } else {
        toast.error(err.toString())
      }
    }
  }

  useEffect(() => {
    if (previewDapp) {
      setPreviewMode(true)

      const fetchDappForPreview = async () => {
        try {
          const response = await fetchGetDapp(previewDapp.id)
          setDappToAdd(response)
        } catch (err) {
          toast.error(err.toString())
        }
      }

      fetchDappForPreview()
    }

  }, [previewDapp])

  return (
    <ModalNew
      title={previewMode ? `Preview - ${previewDapp.name}` : "Add dApp"}
      hasCloseButton
      isOpen={setIsDappSettingsModal}
    >      
      <Line className="mt12 mb16" />
      <div className={Style['form-container']}>
        <GeneralSection
          setDappToAdd={setDappToAdd}
          dappToAdd={dappToAdd}
          blockchains={blockchains}
          previewMode={previewMode}
        />
        <SmartContractsSection
          setDappToAdd={setDappToAdd}
          dappToAdd={dappToAdd}
          previewMode={previewMode}
        />        
      </div>
      {!previewMode && (
        <ButtonNew
          fullWidth
          onClick={() => saveDapp()}
        >
          Confirm & Add
        </ButtonNew>
      )}
    </ModalNew>
  )
}