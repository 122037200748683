/***
 *
 *   GROWTH INDEX DAPPS OVERVIEW
 *
 **********/

import React, {
  useState,
  useEffect,
  useContext,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Loader,
  SmallHeadline,
  Card,
  Paginate,
  ViewContext
} from 'components/lib'

import { DappTable } from 'features/gi/dapp/giDappOverview/components/dappTable'
import { GiBannersContainer } from 'features/gi/shared/components/giBannersContainer/giBannersContainer'
import { GiLegend } from 'features/gi/shared/components/giLegend/giLegend'
import { NewsBanner } from 'features/gi/shared/components/newsBanner/newsBanner'
import { EcosystemBanner } from 'features/gi/dapp/giDappOverview/components/ecosystemBanner'
import { GiDappOverviewFilters } from 'features/gi/dapp/giDappOverview/components/giDappOverviewFilters'
import { useDappOverviewFilters } from 'features/gi/dapp/giDappOverview/hooks/useDappOverviewFilters'
import { fetchGiDappsOverview } from 'features/gi/dapp/giDappOverview/utils/fetches'

export function GiDappOverview() {
  const [isGiDappsDataLoading, setIsGiDappsDataLoading] = useState()
  const [giDappsData, setGiDappsData] = useState()
  const [totalRecords, setTotalRecords] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const {
    giSortValue,
    selectedBlockchain,
    blockchainsOptions,
    verifiedPageSize,
    verifiedBlockchainParam,
    verifiedPage
  } = useDappOverviewFilters()
  const viewContext = useContext(ViewContext)

  useEffect(() => {
    if (verifiedPageSize && verifiedPage && selectedBlockchain && giSortValue && blockchainsOptions && verifiedBlockchainParam) {
      const getGiBlockchainsData = async () => {
        try {
          setIsGiDappsDataLoading(true)
          const fetchedGrowthIndexChainData = await fetchGiDappsOverview(verifiedPageSize, verifiedPage, giSortValue, selectedBlockchain?.slug)
          setGiDappsData(fetchedGrowthIndexChainData.data)
          setTotalRecords(fetchedGrowthIndexChainData.totalDappCount)
          setIsGiDappsDataLoading(false)
        } catch (err) {
          setIsGiDappsDataLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
      getGiBlockchainsData()
    }
  }, [
    selectedBlockchain,
    blockchainsOptions,
    verifiedBlockchainParam,
    verifiedPageSize,
    verifiedPage,
    giSortValue
  ])

  const handleCurrentPage= (currentPage) => {
    searchParams.delete('page')
    searchParams.append('page', currentPage)
    setSearchParams(searchParams)
  }

  return (
    <Card className="mt24">
      <SmallHeadline title="dApp Overview" />
      <GiBannersContainer>
        <GiLegend />
        <EcosystemBanner />
        <NewsBanner />
      </GiBannersContainer>
      <GiDappOverviewFilters
        giSortValue={giSortValue}
        selectedBlockchain={selectedBlockchain}
        blockchainsOptions={blockchainsOptions}
        verifiedBlockchainParam={verifiedBlockchainParam}
        verifiedPageSize={verifiedPageSize}
        verifiedPage={verifiedPage}
      />
      {!isGiDappsDataLoading && giDappsData?.length > 0 ? (
        <DappTable
          data={giDappsData}
        />
      ) : null}
      {!isGiDappsDataLoading && totalRecords ? (
        <div className="align-center mt24 mb24">
          <Paginate
            totalRecords={totalRecords} 
            limit={verifiedPageSize}
            currentPage={verifiedPage}
            onChange={currentPage => handleCurrentPage(currentPage)}
          />
        </div>
      ) : null}
      {isGiDappsDataLoading && <div className="min-height relative"><Loader /></div>}
    </Card>
  )
}
