/***
 *
 *   DASHBOARD PAGE
 *   specific dashboard view
 *
 **********/

import React from 'react'
import { useParams } from 'react-router-dom'

import {
  Animate,
  Loader,
} from 'components/lib'
import { useSeoPage } from 'hooks/useSeoPage'

import { useDashboard } from 'components/hooks/useDashboard'
import { Dashboard } from 'features/dashboard/Dashboard'

export function DashboardPage() {
  const { id } = useParams()
  const { dashboard, isLoadingDashboard } = useDashboard(id)
  useSeoPage({
    // title: to set,
    // description: to set,
    // keywords: to set,
    // ogTitle: to set,
    // ogDescription: to set,
    // ogImage: to set,
    // ogUrl: to set
  })

  return (
    <Animate type="pop">
      {!isLoadingDashboard &&
      dashboard && (
        <Dashboard dashboardData={dashboard} />
      )}
      {isLoadingDashboard && (
        <div className="min-height relative">
          <Loader />
        </div>
      )}
    </Animate>
  )
}