/***
 *
 *   APP NAV
 *   main app navigation
 *
 *   PROPS
 *   title: title of navigation
 *   open: value flag for toggling mobile modal
 *   setOpen: fn for handling mobile modal
 *   items: array of objects containing label, link and icon (optional)
 *
 **********/

import React from 'react'
import { NavLink, matchPath } from 'react-router-dom'

import {
  Icon,
  useLocation,
  useMobile,
  CustomTooltip
} from 'components/lib'
import './app.scss'

export const AppNav = (props) => {
  const isMobile = useMobile()
  const { pathname } = useLocation()

    const verifyLinkClasses = (isDisabled, relatedArr) => {
      if (isDisabled) {
        return 'relative disabled link-tile'
      }

      if (relatedArr?.length > 0 && relatedArr?.some((path) => matchPath(path, pathname))) {
        return 'active link-tile relative'
      }

      return 'link-tile relative'
    }

  return (
    <>
      {props.title && <h5 className="nav-app-title">{props.title}</h5>}
      <nav className={`nav-links ${props.className}`}>
        {props.items?.map((item) => {
          if (item.link) {
            return (
              <NavLink
                to={item.link}
                className={verifyLinkClasses(item.disabled, item.related)}
                onClick={() => props.setOpen(false)}
                data-tooltip-id={item.label}
                data-tooltip-content={item.description}
                data-tooltip-place="right"
                data-tooltip-position-strategy="fixed"
                data-tooltip-offset={32}
                key={item.label}
              >
                {({ isActive }) => (
                  <>
                    {item.icon && (
                      <Icon
                        name={item.icon}
                        width={isMobile ? "18" : "16"}
                        height={isMobile ? "18" : "16"}
                        active={item.related?.length > 0 && item.related?.some((path) => matchPath(path, pathname)) || isActive}
                      />
                    )}
                    {item.label && (
                      <span className="label">
                        {item.label}
                      </span>
                    )}
                    {item.description && !isMobile && (
                      <CustomTooltip
                        id={`${item.label}`}
                        withArrow
                        size="small"
                      />
                    )}
                  </>
                )}
              </NavLink>
            )
          }

          return (
            <div
              className={item.type ? `${item.type} link-tile` : 'link-tile'}
              key={item.label}
              onClick={item.action}
            >
              {item.icon && (
                <Icon
                  name={item.icon}
                  width={isMobile ? "18" : "16"}
                  height={isMobile ? "18" : "16"}
                  color={item.color}
                />
              )}
              {item.label && <span className="label">{item.label}</span>}
            </div>
          )
        })}
      </nav>
    </>
  )
}
