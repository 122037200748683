import React from 'react'
import { ReactSelectWithIcon, Line } from 'components/lib'
import Style from './privacySettings.module.scss'
import eye from 'assets/icons/eye-dark600.svg'
import union from 'assets/icons/union-dark600.svg'
import link from 'assets/icons/link.svg'
import { UsersWithAccess } from '../usersWithAccess/usersWithAccess'
import { PrivacyInformation } from '../privacyInformation/privacyInformation'
import { ShareLink } from '../shareLink/shareLink'
import { InviteEmails } from '../inviteEmails/inviteEmails'

const PrivacySettings = ({
  accessType,
  contentType,
  contentId,
  handlePrivacySettingsSelect,
  accessLink,
  emailList,
  handleEmailListChange,
  handleInviteClick,
  usersData = [],
  id,
  fetchUsers,
  lookerId,
  validInput,
}) => {
  return (
    <div className={Style.container}>
      {accessType && (
        <div className={Style.accessTypeSettingsContainer}>
          <ReactSelectWithIcon
            label="Privacy"
            name="privacy"
            classNamePrefix="react-select-with-icon-outline-dark"
            value={accessType}
            options={[
              { label: 'Public', value: 'public', icon: eye },
              { label: 'Private', value: 'private', icon: union },
              { label: 'Link', value: 'link', icon: link },
              {
                label: 'Only invited',
                value: 'only invited',
                displayValue: 'only invited',
                icon: eye,
              },
            ]}
            placeholder={accessType}
            isSearchable={false}
            change={(option) => handlePrivacySettingsSelect(option)}
          />
        </div>
      )}

      <PrivacyInformation accessType={accessType} contentType={contentType} />

      <Line marginTop marginBottom />
      <div className={Style.inviteSettingsContainer}>
        {accessType === 'link' && (
          <ShareLink accessLink={accessLink} contentType={contentType} />
        )}
        {accessType !== 'link' && accessType !== 'private' && (
          <ShareLink
            accessLink={contentId}
            contentType={contentType}
            isId={true}
            lookerId={lookerId}
          />
        )}

        {accessType === 'only invited' && (
          <>
            <InviteEmails
              emailList={emailList}
              handleEmailListChange={handleEmailListChange}
              handleInviteClick={handleInviteClick}
              validInput={validInput}
            />
            <UsersWithAccess
              usersData={usersData}
              contentId={id}
              fetchUsers={fetchUsers}
              contentType={contentType}
            />
          </>
        )}
      </div>
    </div>
  )
}

export default PrivacySettings
