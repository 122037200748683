import React, { useState } from 'react'
import Select, { components } from 'react-select'

import {
  Label,
  Icon,
  Typography
} from 'components/lib'
import { palette } from 'utils/constans'
import { transformSnakeCaseToSentence } from 'utils/helpers'


const MultiOption = ({
  getStyles,
  _Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false)
  const onMouseDown = () => setIsActive(true)
  const onMouseUp = () => setIsActive(false)
  const onMouseLeave = () => setIsActive(false)

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    display: 'flex',
    backgroundColor: bg,
  }

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style
  }

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input type="checkbox" checked={isSelected} />
      {rest.data?.icon && (
        <img
          src={rest.data.icon}
          alt={rest.data.label}
          style={{ width: '1.6rem', height: '1.6rem' }}
        />
        )}
      {children}
    </components.Option>
  )
}

export const CustomSelect = ({
  label,
  options,
  change,
  name,
  placeholder,
  placeholderIcon,
  isSearchable,
  withArrow,
  classNamePrefix,
  value,
  defaultValue,
  disabled,
  size,
  prefix,
  isMulti,
  isDisabled,
}) => {
  const {
    Option,
    Placeholder,
    SingleValue
  } = components

  const customStyles = {
    menu: (base) => ({ 
      ...base,
      width: 'max-content',
      minWidth: '100%',
      borderRadius: '0.6rem',
      marginTop: '0.8rem'
    }),
    placeholder: (base) => ({ 
      ...base,
      fontSize: '1.4rem',
      color: palette.gray900,
      fontWeight: 500,
      display: 'flex',
      gap: '0.6rem'
    }),
    valueContainer: (base) => ({
      ...base,
      paddingLeft: '0.6rem',
      paddingRight: '0',
      // gap: '0',
    }),
    singleValue: (base) => ({
      ...base,
      fontSize: '1.4rem',
      color: palette.gray900,
      fontWeight: 500,
      display: 'flex',
      gap: '0.6rem'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0rem',
    }),
    indicatorsContainer: (base) => ({
      ...base,
      marginLeft: '0.4rem',
      marginRight: '0.6rem',
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: '0.6rem'
    }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? palette.primary : palette.gray100,
      '&:hover': {
        borderColor: state.isFocused ? palette.primary : palette.gray100,
      },
      boxShadow: state.isFocused ? '0 0.2rem 0.3rem 0 rgba(16, 24, 32, 0.0509803922)' : 'none',
      minHeight: '3.6rem',
      padding: '0 0.4rem 0 0.6rem',
      borderRadius: '0.6rem',
      opacity: disabled ? 0.5 : 1,
      pointerEvents: disabled ? 'none' : 'auto',
      backgroundColor: disabled && palette.gray50,
      cursor: 'pointer'
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused || state.isSelected ? palette.white : palette.white,
      '&:hover': {
        borderColor: state.isFocused || state.isSelected ? palette.white : palette.white,
      },
      cursor: 'pointer',
      color: palette.gray900,
      display: 'flex',
      alignItems: 'center',
      gap: '0.8rem'
    })
  }

  const IconOption = (props) => (
    <Option {...props}>
      {props.data.icon && <img src={props.data.icon} alt={props.data.label} style={{ width: '1.6rem', height: '1.6rem' }} />}
      {props.data.label}
    </Option>
  )

  const CustomSingleValue = ({ children, ...props }) => (
    <SingleValue {...props}>
      {prefix && <p>{prefix}</p>}
      {children}
    </SingleValue>
  )

  const CustomDropdownIndicator = () => <Icon width="12" height="12" name="dropdownArrow" color="gray600" />

  const IconValuePlaceholder = ({
    children,
    ...props
  }) => {
    const selectedOption = props.selectProps.options.find(option => option.value === props.selectProps.placeholder)

    return (
      <Placeholder {...props}>
        {selectedOption?.icon && selectedOption?.label && (
          <img src={selectedOption.icon} alt={selectedOption.label} />
        )}
        {!selectedOption?.icon && placeholderIcon && placeholderIcon}
        {transformSnakeCaseToSentence(children)}
      </Placeholder>
    )
  }

  const MultiValue = ({ getValue, index, ...props }) => {
    return (
      <>
        {index === 0 && prefix}
        &nbsp;
        <components.MultiValueLabel {...props}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap:'0.6rem' }}>
            {(props.data?.icon || props.data?.logo) && (
              <img
                src={props.data.icon || props.data?.logo}
                alt={props.data.label}
                style={{ width: '1.6rem', height: '1.6rem' }}
              />
            )}
            <Typography
              tag="p"
              size="s"
              weight="regular"
              text={props.data.label}
            />
          </div>
        </components.MultiValueLabel>
      </>
    )
  }

  return (
    <div>
      {label && <Label text={label} size={size} />}

      <Select
        value={value}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={options}
        onChange={change}
        isMulti={isMulti}
        isDisabled={isDisabled}
        id={name}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        classNamePrefix={"react-select-multi-with-icon"}
        styles={customStyles}
        components={{
          Option: isMulti ? MultiOption : IconOption,
          MultiValue,
          SingleValue: CustomSingleValue,
          Placeholder: IconValuePlaceholder,
          DropdownIndicator: withArrow ? CustomDropdownIndicator : null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  )
}
