/***
 *
 *   SIDEBAR
 *
 **********/

import React, { useState } from 'react'
import {
  Logo,
  Icon,
  AppNav,
  BottomNav,
  useMobile,
  Line,
  IconButton,
} from 'components/lib'
import { useNavBottomItems } from './hooks/useNavBottomItems'
import { useNavCreatorToolsItems } from './hooks/useNavCreatorToolsItems'
import { useNavRankingItems } from './hooks/useNavRankingItems'
import './sidebar.scss'

export function Sidebar() {
  const isMobile = useMobile()
  // state
  const [open, setOpen] = useState(false) // mobile is open
  const [fade, _setFade] = useState('')
  const navRankingItems = useNavRankingItems()
  const navCreatorToolsItems = useNavCreatorToolsItems()
  const navBottomItems = useNavBottomItems()

  return (
    <aside className={`sidebar sidebar-mobile ${open ? 'open' : 'close'}`}>
      {isMobile && (
        <div className="bar-wrapper">
          <Logo />

          <IconButton
            icon={<Icon name={open ? 'hamburgerClose' : 'hamburgerOpen'} width="20" height="20" />}
            onClick={() => setOpen(!open)}
          />
        </div>
      )}

      {!isMobile && <Logo />}

      <section className="menu">
        <div>
          <AppNav
            title="Ranking"
            items={navRankingItems}
            setOpen={setOpen}
            className={`menu ${fade}`}
          />
          <Line color="secondary600" />
          <AppNav
            title="Creator Tools"
            items={navCreatorToolsItems}
            setOpen={setOpen}
            className={`menu ${fade}`}
          />
        </div>
        <BottomNav items={navBottomItems} setOpen={setOpen} className="bottom" />
      </section>
    </aside>
  )
}
