import { GiDappBasicPage } from 'views/gi/dapp/giDappBasicPage'
import { DappUsersPage } from 'views/gi/dapp/dappUsersPage'
import { GiDappBreakdownPage } from 'views/gi/dapp/giDappBreakdownPage'
import { GiDappOverviewPage } from 'views/gi/dapp/giDappOverviewPage'

import { paths } from 'utils/paths'

const Routes = [
  {
    path: paths.giDappOverview,
    view: GiDappOverviewPage,
    layout: 'redesignApp',
    title: 'dApp - Ranking',
  },
  {
    path: `/:dapp/${paths.giDapp}`,
    view: GiDappBasicPage,
    layout: 'redesignApp',
  },
  {
    path: `/:dapp/${paths.giDapp}/details`,
    view: GiDappBreakdownPage,
    layout: 'redesignApp',
  },
  {
    path: `/:dapp/users`,
    view: DappUsersPage,
    layout: 'redesignApp',
  },
  {
    path: `/:dapp/users/:address`,
    view: DappUsersPage,
    layout: 'redesignApp',
  }
]

export default Routes
