import React from 'react'

import {
  useNavigate,
  IconLib,
  Icon,
  CustomSimpleAreaChart,
  useMobile,
} from 'components/lib'
import {
  handleGrowthIndexLevel,
  capitalizeWords,
} from 'utils/helpers'
import { paths } from 'utils/paths'

import { OverflowElements } from 'features/gi/dapp/shared/components/overflowElements/OverflowElements'
import { determineTrend } from 'features/gi/shared/utils/helpers'
import Style from './dappTable.module.scss'

export const DappTable = ({
  data
 }) => {
  const navigate = useNavigate()
  const isMobile = useMobile()

  const moveToBasicPage = (slug) => {
    navigate(`/${slug}/${paths.giDapp}`)
  }

  const determineRankChange = (change) => {
    if (change === 0) {
      return ''
    } else if (change > 0) {
      return (
        <div className={Style.changeRankContainer}>
          <IconLib
            pack="own"
            iconHeight="10"
            iconWidth="7"
            image="trendUp2"
          />
          <p className={Style.progressRank}>{change}</p>
        </div>
      )
    } else {
      return (
        <div className={Style.changeRankContainer}>
          <IconLib
            pack="own"
            iconHeight="10"
            iconWidth="7"
            image="trendDown2"
          />
          <p className={Style.regressRank}>{Math.abs(change)}</p>
        </div>
      )
    }
  }

  return (
    <>
      {data && (
        <div className={Style.tableWrapper}>
          <table className={Style.dappTable}>
            <thead>
              <tr className={Style.head}>
                {!isMobile && <th>No</th>}
                <th>
                  dApp
                </th>
                <th>
                  Blockchain
                </th>
                <th>
                  <div className={Style.growthIndexCell}>
                    {!isMobile && (
                      <Icon
                        name="growthIndex"
                        color="gray500"
                        width="16"
                        height="16"
                      />
                    )}
                    Growth Index
                  </div>
                </th>
                <th>
                  <div className={Style.growthIndexTimelineColumnContainer}>
                    {!isMobile && (
                      <Icon
                        name="growthIndex"
                        color="gray500"
                        width="16"
                        height="16"
                      />
                    )}
                    Growth Index Timeline (90d)
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 && data.map((row, index) => (
                <tr
                  className={Style.row}
                  onClick={() => moveToBasicPage(row.dApp.slug)}
                  key={row.dApp.id}
                >
                  {!isMobile && (
                    <td>
                      <div className={Style.numberCell}>
                        {row.rank}
                        {determineRankChange(row.rankChange)}
                      </div>
                    </td>
                  )}
                  <td>
                    <div className={Style.dAppCell}>
                      <img
                        className={Style.dApplogo}
                        src={row?.dApp?.icon}
                      />
                      {row.dApp.name}
                    </div>
                  </td>
                  <td className={Style.blockchainCell}>
                    <OverflowElements
                      elements={row.dApp.blockchains}
                      type="blockchain"
                      size="m"
                      id={row.dApp.id}
                      withFrame
                    />
                  </td>
                  <td>
                    <div className={Style.growthIndexCell}>
                      <IconLib
                        pack="own"
                        image={`growthIndex${capitalizeWords(handleGrowthIndexLevel(row.growthIndex))}`}
                        size="24"
                      />
                      {row.growthIndex?.toFixed(0) || 0}
                    </div>
                  </td>
                  <td className={Style.chartCell}>
                    <CustomSimpleAreaChart
                      data={row.data || []}
                      style={{
                        height: "72px",
                        width: "100%",
                        margin: "4px 0 2px 0",
                      }}
                      trend={determineTrend(row.data || [])}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.length === 0 && (
            <div className={Style.announcementContainer}>
              <p className={Style.announcement}>
                No data
              </p>
            </div>
          )}
        </div>
      )}
    </>
  )
}
