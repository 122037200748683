/***
 *
 *   useVerifiedFiltersParam hook
 * 
 **********/

import _React, {
  useState,
  useEffect,
  useContext
} from 'react'
import {
  useSearchParams,
  useNavigate,
  useLocation
} from 'react-router-dom'

import { ViewContext } from 'components/lib'
import { paths } from 'utils/paths'

import { fetchOtherDappsUsedData } from 'features/gi/dapp/dappUsers/utils/fetches'
import { useBasicDappsData } from 'features/gi/dapp/shared/hooks/useBasicDappsData'

export const useDappUserInsightsFilters = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, _setSearchParams] = useSearchParams()
  const [verifiedDappSegment, setVerifiedDappSegment] = useState()
  const [verifiedBlockchainsParam, setVerifiedBlockchainsParam] = useState()
  const [verifiedOtherDappsUsedParam, setVerifiedOtherDappsUsedParam] = useState()
  const [verifiedParamsStr, setVerifiedParamsStr] = useState('')
  const [selectedBlockchains, setSelectedBlockchains] = useState()
  const [selectedOtherDappsUsed, setSelectedOtherDappsUsed] = useState()
  const [selectedDapp, setSelectedDapp] = useState()
  const pageParam = searchParams.get('page')
  const sortByParam = searchParams.get('sort-by')
  const sortTypeParam = searchParams.get('sort-type')
  const searchAddressParam = searchParams.get('search-address')
  const pageSizeParam = searchParams.get('page-size')
  const minDepositParam = searchParams.get('min-deposit')
  const maxDepositParam = searchParams.get('max-deposit')
  const minInteractionsParam = searchParams.get('min-interactions')
  const maxInteractionsParam = searchParams.get('max-interactions')
  const lastInteractionStartDateParam = searchParams.get('last-interaction-start-date')
  const lastInteractionEndDateParam = searchParams.get('last-interaction-end-date')
  const joinedStartDate = searchParams.get('joined-start-date')
  const joinedEndDate = searchParams.get('joined-end-date')
  const otherDappsUsedParam = searchParams.get('other-dapps-used')
  const blockchainsParam = searchParams.get('blockchains')
  const [ , dappSegment, , addressSegment ] = location.pathname.split('/')
  const [usedDappsOptions, setUsedDappsOptions] = useState()
  const [_isUsedDappsOptionsLoading, setIsUsedDappsOptionsLoading] = useState(false)
  const viewContext = useContext(ViewContext)

  const {
    basicDappsData,
  } = useBasicDappsData()

  useEffect(() => {
    if (basicDappsData.length > 0) {
      if (!basicDappsData.some(obj => obj.slug?.toLowerCase() === dappSegment.toLowerCase())) {
        setVerifiedDappSegment()
        navigate(paths.giDappOverview)
      }
      setVerifiedDappSegment(dappSegment.toLowerCase())
      const filteredSelectedDapp = basicDappsData.filter(obj => obj.slug.toLowerCase() === dappSegment.toLowerCase())[0]
      setSelectedDapp(filteredSelectedDapp)
    }

  }, [
    basicDappsData,
    dappSegment
  ])

  useEffect(() => {
    if (verifiedDappSegment) {
      const getUsedDappsData = async () => {
        try {
          setIsUsedDappsOptionsLoading(true)
          const response = await fetchOtherDappsUsedData(verifiedDappSegment)
          setUsedDappsOptions(response.data)
          setIsUsedDappsOptionsLoading(false)
        } catch (err) {
          setIsUsedDappsOptionsLoading(false)
          viewContext && viewContext.handleError(err)
        }
      }
  
      getUsedDappsData()
    }
  }, [verifiedDappSegment])

  useEffect(() => {
    if (otherDappsUsedParam && usedDappsOptions?.length > 0) {
      const otherDappsUsedParamArr = otherDappsUsedParam.split(',')
      let verifiedOtherDappsUsedParamArr = []
      let filteredOtherDappsUsedArr = []
      otherDappsUsedParamArr.forEach(param => {
        const matchingObject = usedDappsOptions.find(
            obj => obj.slug.toLowerCase() === param.toLowerCase() &&
            obj.slug !== filteredOtherDappsUsedArr.slug
          )
        if (matchingObject) {
          verifiedOtherDappsUsedParamArr.push(param)
          filteredOtherDappsUsedArr.push(matchingObject)
        }
      })
      if (verifiedOtherDappsUsedParamArr.length > 0) {
        const verifiedCompareWithParamStr = verifiedOtherDappsUsedParamArr.join(',').toLowerCase()
        setVerifiedOtherDappsUsedParam(verifiedCompareWithParamStr)
        setSelectedOtherDappsUsed(filteredOtherDappsUsedArr)
      } else {
        setVerifiedOtherDappsUsedParam()
        setSelectedOtherDappsUsed([])
      }
    } else {
      setVerifiedOtherDappsUsedParam()
      setSelectedOtherDappsUsed([])
    }
  }, [
    JSON.stringify(usedDappsOptions),
    otherDappsUsedParam
  ])

  useEffect(() => {
    if (blockchainsParam && selectedDapp?.blockchains.length > 0) {
      const blockchainNames = blockchainsParam.split(',').map(name => name.trim().toLocaleLowerCase())
      
      const matchedBlockchains = selectedDapp.blockchains.filter(blockchain => blockchainNames.includes(blockchain.slug))
      
      if (matchedBlockchains.length > 0) {
        // If any matches are found, create a string of matched blockchain names
        const matchedBlockchainsStr = matchedBlockchains.map(b => b.slug).join(',')
  
        // Update the selected blockchains and verified blockchains param
        setSelectedBlockchains(matchedBlockchains)
        setVerifiedBlockchainsParam(matchedBlockchainsStr)
        
        // Update the verifiedParamsStr by replacing or adding the blockchains parameter
        const newVerifiedParamsStr = verifiedParamsStr.replace(/blockchains=[^&]*/, `blockchains=${matchedBlockchainsStr}`)
        setVerifiedParamsStr(newVerifiedParamsStr)
      } else {
        // If no matches are found, set selected blockchains and verified blockchains param to undefined
        setSelectedBlockchains([])
        setVerifiedBlockchainsParam(undefined)
        
        // Remove the blockchains parameter from the verifiedParamsStr
        const newVerifiedParamsStr = verifiedParamsStr.replace(/blockchains=[^&]*&?/, '')
        setVerifiedParamsStr(newVerifiedParamsStr)
      }
    } else {
      setSelectedBlockchains([])
      setVerifiedBlockchainsParam(undefined)
    }
  }, [selectedDapp, blockchainsParam]);

  return {
    verifiedAddressSegment: addressSegment,
    verifiedPage: pageParam ? Number(pageParam) : 1,
    verifiedPageSize: pageSizeParam || 10,
    verifiedDappSegment: verifiedDappSegment,
    selectedDapp: selectedDapp,
    searchAddressParam: searchAddressParam,
    sortTypeParam: sortTypeParam,
    sortByParam: sortByParam,
    usedDappsOptions: usedDappsOptions,
    verifiedOtherDappsUsedParam: verifiedOtherDappsUsedParam,
    selectedOtherDappsUsed: selectedOtherDappsUsed,
    minDepositParam: minDepositParam,
    maxDepositParam: maxDepositParam,
    minInteractionsParam: minInteractionsParam,
    maxInteractionsParam: maxInteractionsParam,
    lastInteractionStartDateParam: lastInteractionStartDateParam,
    lastInteractionEndDateParam: lastInteractionEndDateParam,
    joinedEndDate: joinedEndDate,
    joinedStartDate: joinedStartDate,
    verifiedBlockchainsParam: verifiedBlockchainsParam,
    selectedBlockchains: selectedBlockchains
  }
}