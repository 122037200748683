import React from 'react'

import {
  ButtonNew,
  Typography,
  Icon,
} from 'components/lib'

import Style from './AddDappTopBar.module.css'

export const AddDappTopBar = ({
  setIsDappSettingsModal,
  setPreviewDapp
}) => (
  <div className={Style['add-dapp-top-bar']}>
    <Typography
      tag="h1"
      text="Dashboard Builder"
      size="m"
      weight="medium"
      color="gray900"
    />
    <ButtonNew
      onClick={() => {
        setPreviewDapp()
        setIsDappSettingsModal(true)
      }}
      startIcon={<Icon name="plus" width="16" height="16" />}
    >
      Add dApp
    </ButtonNew>
  </div>
)