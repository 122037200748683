/***
 *
 *   GI ECOSYSTEM BASIC DASHBOARD COMPONENT
 *
 **********/

import React, { useContext } from 'react'

import {
  Title,
  AuthContext,
  Loader,
  Breadcrumbs,
  useLocation,
} from 'components/lib'
import {
  paths
} from 'utils/paths'
import { plan } from 'utils/constans'
import {
  useSeoPage
} from 'hooks/useSeoPage'

import { FiltersSection } from 'features/gi/ecosystem/shared/components/filtersSection/filtersSection'
import { BasicValuesSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/basicValuesSection/BasicValuesSection'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { ComparisionSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/comparisionSection/ComparisionSection'
import { TgGrowthIndexTimeLineSection } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/tgGrowthIndexTimelineSection/tgGrowthIndexTimelineSection'
import { DashboardsSection } from 'features/gi/shared/components/dashboardsSection/DashboardsSection'

export function GiEcosystemBasicDashboard() {
  const authContext = useContext(AuthContext)
  const {
    verifiedBlockchainSegment,
    verifiedParamsStr,
    selectedBlockchain,
  } = useVerifiedFiltersParams()
  const location = useLocation()
  useSeoPage({
    title: `${selectedBlockchain?.blockchain?.name || ' '} - Crypto`,
    description: `View ${selectedBlockchain?.blockchain?.name || ' '} activity, explore detailed chain analysis metrics & track developer activity`,
    keywords: ['crypto activity', 'developer activity', 'chain analysis'],
    ogTitle: `${selectedBlockchain?.blockchain?.name || ' '} - Crypto`,
    ogDescription: `View ${selectedBlockchain?.blockchain?.name || ' '} activity, explore detailed chain analysis metrics & track developer activity`,
    ogImage: selectedBlockchain?.blockchain?.logo
    // ogUrl: to set
  })
  const [ , firstSegment ] = location.pathname.split('/')
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giEcosystemOverview,
      name: 'Ecosystem Ranking',
    },
    {
      key: 'basic',
      url: `/${firstSegment}/${paths.giEcosystem}${location.search}`,
      name: `${selectedBlockchain?.blockchain?.name || ''} - Basic`
    },
  ]

  return (
    <>
      <div className="mb24">
        <Breadcrumbs items={breadcrumbsItems}  />
      </div>
      {verifiedBlockchainSegment && verifiedParamsStr ? (
        <>
          <BasicValuesSection />
          <Title small className="mt24 mb16">Historical Values</Title>
          <FiltersSection />
          <TgGrowthIndexTimeLineSection />
          {authContext?.user?.plan === plan.alephZero && (
            <ComparisionSection />
          )}
          {selectedBlockchain?.blockchain?.dappanalytics && (
            <DashboardsSection
              slug={selectedBlockchain.blockchain.slug}
              type="blockchain"
            />
          )}
        </>
        ) : (
          <div className="min-height relative"><Loader /></div>
      )}
    </>
  )
}