export const verifyDateRangeParam = (str) => {
  if (str === '3 months' ||
  str === '6 months' ||
  str === 'last year' ||
  str === 'all time') {

    return str
  } else {

    return '3 months'
  }
}

export const verifyBlockchainBreakdownParam = (str) => {
  if (str === 'true') {

    return true
  } else {

    return false
  }
}

// make nicly numbers
export function tidyNumbers(numbers) {
  return Intl.NumberFormat('en', { notation: 'compact' }).format(numbers);
}

export const determineTrend = (values) => {
  const startValue = values[0]?.value || 0
  const lastIndex = values.length - 1
  const endValue = values[lastIndex]?.value || 0

  if (startValue > endValue) {
    return 'negative'
  } else {
    return 'positive'
  }
}