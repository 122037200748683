/***
 *
 *   OVERVIEW
 *   Ranking of dapps
 *
 **********/

import React from 'react'

import {
  Animate,
  Breadcrumbs,
  MainContentContainer,
} from 'components/lib'
import {
  useSeoPage
} from 'hooks/useSeoPage'
import { paths } from 'utils/paths'
import ogImage from 'assets/images/og/ecosystem-ranking.png'

import { GiDappOverview } from 'features/gi/dapp/giDappOverview/giDappOverview'

export function GiDappOverviewPage() {
  useSeoPage({
    title: 'Crypto App Ranking',
    description: 'Browse a large ranking of crypto apps, gain insights on your users, and use analytics to better understand ',
    keywords: ['crypto app', 'analytics', 'users', 'ranking'],
    ogTitle: 'Crypto App Ranking',
    ogDescription: 'Browse a large ranking of crypto apps, gain insights on your users, and use analytics to better understand ',
    ogImage: ogImage,
    // ogUrl: to set
  })
  const breadcrumbsItems = [
    {
      key: 'overview',
      url: paths.giDappOverview,
      name: 'dApp Ranking',
    },
  ]

  return (
    <MainContentContainer>
      <Animate type="pop">
        <div className="mb24">
          <Breadcrumbs items={breadcrumbsItems} />
        </div>
        <GiDappOverview />
      </Animate>
    </MainContentContainer>
  )
}
