import { z } from 'zod'

export const ElementSchema = z.object({
  _id: z.string(),
  id: z.string(),
  queryId: z.number().optional(),
  visType: z.string().optional(),
  title: z.string(),
  text: z.string().optional(),
  type: z.string(),
  metric: z.string().optional(),
  breakdown: z.boolean().optional(),
  __v: z.number().optional(),
  filters: z.any()
})


// TO DO - better to override?
// export const ElementSchema = z.union([
//   z.object({
//     _id: z.string(),
//     id: z.string(),
//     title: z.string(),
//     visType: z.literal('singleValue'),
//     type: z.string(),
//     prefix: z.string().optional(),
//     info: z.string().optional(),
//     showTrend: z.boolean, // default false
//     formatValue: z.boolean().optional,
//     data: z.unknown(),
//   }),
//   z.object({
//     _id: z.string(),
//     id: z.string(),
//     title: z.string(),
//     visType: z.union([
//       z.literal('areaChart'),
//       z.literal('barChart'),
//       z.literal('stackedBarChart'),
//       z.literal('multiAreaChart'),
//       z.literal('lineChart'),
//       z.literal('multiLineChart'),
//       z.literal('table')
//     ]),
//     type: z.string(),
//     data: z.unknown(),
//     round: z.number().optional(),
//     minValue: z.number().optional(),
//     maxValue: z.number().optional(),
//     info: z.string().optional(),
//     formatValue: z.boolean().optional(), // default false
//     prefixValue: z.string().optional(),
//   }),
//   z.object({
//     _id: z.string(),
//     id: z.string(),
//     text: z.string(),
//     type: z.literal('text'),
//   }),
//   z.object({
//     _id: z.string(),
//     id: z.string(),
//     text: z.string(),
//     type: z.literal('button'),
//     link: z.string(),
//   }),
// ])

export type TElement = z.infer<typeof ElementSchema>