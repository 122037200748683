/***
 * HOOK of rankings nav items
 *
 **********/

import { paths } from 'utils/paths'

export function useNavRankingItems() {

  const navOverviewItems = [
    {
      label: 'Ecosystem',
      icon: 'tgDashboards',
      link: '/',
      related: [paths.giEcosystemOverview, `/:blockchain/${paths.giEcosystem}`, `/:blockchain/${paths.giEcosystem}/details`]
    },
    {
      label: 'dApp',
      icon: 'dapp',
      link: paths.giDappOverview,
      related: [paths.giDappOverview, `/:dapp/${paths.giDapp}`, `/:dapp/${paths.giDapp}/details`, `/:dapp/users`, `/:dapp/users/:address`],
      disabled: false,
    },
  ]

  return navOverviewItems
}
