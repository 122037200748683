/***
 *
 *   TG GROWNTH INDEX TIMELINE BREAKDOWN COMPONENT
 *
 **********/

import React from 'react'

import {
  Card,
  Loader,
  CustomAreaChart,
  useMobile,
  Title,
} from 'components/lib'

import { TG_GROWTH_INDEX } from 'features/gi/ecosystem/shared/utils/constans'
import { GaugeSection} from 'features/gi/ecosystem/shared/components/gaugeSection/gaugeSection'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'
import { CoverChartSection } from 'features/gi/ecosystem/shared/components/coverChartSection/coverChartSection'
import { BreakdownContainer } from 'features/gi/ecosystem/giEcosystemBreakdownDashboard/components/breakdownContainer/breakdownContainer'
import { VerticalLine } from 'features/gi/ecosystem/giEcosystemBreakdownDashboard/components/verticalLine/verticalLine'

export function TgGrowthIndexBreakdownSection({
  tgGrowthIndexTimeline,
  isTgGrowthIndexTimelineLoading,
  blockchain,
  comparison,
}) {
  const isMobile = useMobile()
  let lastGiDataObj
  let mainMeasure

  if (tgGrowthIndexTimeline?.length > 0 && comparison && blockchain) {
    const keys = Object.keys(tgGrowthIndexTimeline[0])
    if (keys.includes('differential')) {
      mainMeasure = keys.filter(item => item !== 'dimension' && item !== 'differential')[0]
  
  
      function extractGrowthIndexes(data) {
        const grouped = data.reduce((acc, item) => {
            if (!acc[item.differential]) {
                acc[item.differential] = [];
            }
            acc[item.differential].push(item);
            return acc;
        }, {});
    
        const result = {};
        for (const [key, value] of Object.entries(grouped)) {
            const lastItem = value[value.length - 1]
            result[key] = lastItem[mainMeasure]
        }
    
        return result
      }
  
      const gaucheData = extractGrowthIndexes(tgGrowthIndexTimeline)
  
      lastGiDataObj = {
        blockchain: {
          tg_growth_index: {
            currentValue: gaucheData[blockchain.name]
          }
        },
        cumulative: {
          tg_growth_index: {
            currentValue: gaucheData[comparison.name]
          }
        }
      }
    } else {
      lastGiDataObj = {
        blockchain: {
          tg_growth_index: {
            currentValue: tgGrowthIndexTimeline?.[tgGrowthIndexTimeline?.length -1][TG_GROWTH_INDEX]
          }
        }
      }
    }
  } else {
    lastGiDataObj = {
      blockchain: {
        tg_growth_index: {
          currentValue: tgGrowthIndexTimeline?.[tgGrowthIndexTimeline?.length -1][TG_GROWTH_INDEX]
        }
      }
    }
  }

  return (
    <Card darkMode className="mt24">
      <Title small darkMode className="mb16">TG Growth Index Timeline</Title>
      <BreakdownContainer>
        <GaugeSection
          title="Ecosystem Growth Index"
          data={lastGiDataObj}
          loadingData={isTgGrowthIndexTimelineLoading}
        />
        {!isMobile && <VerticalLine />}
        {isTgGrowthIndexTimelineLoading && <div className="min-height relative"><Loader /></div>}
        {!isTgGrowthIndexTimelineLoading &&
        tgGrowthIndexTimeline?.length > 0 && (
          <CustomAreaChart
            darkMode={true}
            data={tgGrowthIndexTimeline}
            round={0}
            maxValue={100}
          />
        )}
        {!isTgGrowthIndexTimelineLoading &&
        tgGrowthIndexTimeline?.data?.length === 0 && (
          <div className="min-height relative"><p className="align-xy">No data</p></div>
        )}
        {!isTgGrowthIndexTimelineLoading && tgGrowthIndexTimeline?.message && <CoverChartSection />} 
      </BreakdownContainer>
    </Card>
  )
}
