/***
 *
 *   BASIC VALUES SECTION
 *
 **********/

import React from 'react'
import { Button, Card, useNavigate, useLocation, Title } from 'components/lib'
import { paths } from 'utils/paths'

import { SingleValueGrid } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/singleValueGrid/singleValueGrid'
import { BasicValuesContainer } from 'features/gi/ecosystem/giEcosystemBasicDashboard/components/basicValuesContainer/BasicValuesContainer'
import { GaugeSection } from 'features/gi/ecosystem/shared/components/gaugeSection/gaugeSection'
import { useBasicValuesData } from 'features/gi/ecosystem/giEcosystemBasicDashboard/hooks/useBasicValuesData'
import { useVerifiedFiltersParams } from 'features/gi/ecosystem/shared/hooks/useVerifiedFiltersParams'

export function BasicValuesSection() {
  const navigate = useNavigate()
  const location = useLocation()
  const { basicValuesData, isBasicValuesDataLoading } = useBasicValuesData()
  const { verifiedBlockchainSegment } = useVerifiedFiltersParams()

  return (
    <Card darkMode className="mt24">
      <Title small darkMode className="mb16">
        Last Week Stats
      </Title>
      <BasicValuesContainer>
        <GaugeSection
          title="Ecosystem Growth Index"
          data={basicValuesData}
          loadingData={isBasicValuesDataLoading}
        />
        <SingleValueGrid
          data={basicValuesData}
          loadingData={isBasicValuesDataLoading}
        />
        <Button
          fluid
          title="Details"
          text="Details"
          darkPrimary
          icon="traditionalArrow"
          iconButton
          iconMediumPosition
          iconColor="primary"
          iconWidth="19"
          iconHeight="13"
          iconPack="own"
          alignRight
          action={() =>
            navigate(
              `/${verifiedBlockchainSegment}/${paths.giEcosystem}/details${location.search}`
            )
          }
        />
      </BasicValuesContainer>
    </Card>
  )
}
